import { Component, Match, Show, Switch, createSignal, createMemo } from 'solid-js'
import { DropdownData } from '../../../shared/components/Multiselect'
import { CareerStore } from '../career-list'
import { BreadcrumbType } from '../../../shared/components/Breadcrumb'
import FilterBubbles from './FilterBubbles'
import { useEventBus } from '../context/EventBusContext'
import Filters from './Filters'

type Props = {
	store: CareerStore
	onFilterChange: (path: string, items: DropdownData[]) => void
	onRangeFilterChange: (path: string, value: DropdownData[]) => void
	onFilterOptionDisabled: (path: string, value: string) => void
	onResetLocationSearch: () => void
	breadcrumb: BreadcrumbType[]
	resetFilter: () => void
	mode?: 'full' | 'list' | 'search'
}

const FilterListMode: Component<Props> = props => {
	const eventBus = useEventBus()

	const selectedFilterOptions = createMemo(() => props.store.visuallySelectedFilterOptions())

	const [showFilter, setFilter] = createSignal(false)
	const toggle = () => setFilter(!showFilter())

	return (
		<div class="career-filter" classList={{ 'filter-opened': showFilter() }}>
			<div class="filter-head" onClick={toggle}>
				<div class="flex flex-col">
					<button class="filter-toggle">
						<div class="icon">
							<img
								src="/typo3conf/ext/basicdistribution/Resources/Public/Icons/Career/bwegt-icons-HR-filter.svg"
								alt="filter"
							/>
						</div>
						<Show when={!showFilter()} fallback={<span>Weniger Optionen</span>}>
							<span>Weitere Optionen</span>
						</Show>
					</button>

					<Show when={selectedFilterOptions().length > 0}>
						<div class="chip-wrapper flex flex-row gap-4">
							<button
								type="button"
								class="flex items-center gap-2 rounded-md border border-yellow px-2 py-1 text-sm"
								disabled={props.store.postingsPagination.isLoading}
								onClick={() => props.resetFilter()}>
								<span class="filter-tag-text">Auswahl zurücksetzen</span>
								<span class="tiny-button my-auto">
									<svg aria-hidden="true" viewBox="0 0 1 1">
										<use href="/typo3conf/ext/basicdistribution/Resources/Public/Icons/Symbols/sprite.symbol.svg#close" />
									</svg>
								</span>
							</button>
							<FilterBubbles
								onResetLocationSearch={props.onResetLocationSearch}
								store={props.store}
								onFilterOptionDisabled={props.onFilterOptionDisabled}
							/>
						</div>
					</Show>
				</div>
			</div>

			<Show when={showFilter()}>
				<div class="filter-body">
					<Filters
						store={props.store}
						onFilterChange={props.onFilterChange}
						onRangeFilterChange={props.onRangeFilterChange}
						onFilterOptionDisabled={props.onFilterOptionDisabled}
						mode={props.mode}
					/>
					<div class="apply-and-reset">
						<div class="flex flex-1">
							<button
								type="submit"
								class="apply-filter-btn"
								onClick={() => {
									eventBus.emit({ type: 'searchTriggered' })
									toggle()
								}}>
								<svg aria-hidden="true" viewBox="0 0 512 512">
									<use href="/typo3conf/ext/basicdistribution/Resources/Public/Icons/Symbols/sprite.symbol.svg#ios-search-strong" />
								</svg>
								<span>Job finden</span>
							</button>
						</div>
						<div class="flex-1">
							<Show when={selectedFilterOptions().length > 0 && props.store.plz}>
								<button
									class="reset mt-6"
									type="button"
									onClick={() => props.resetFilter()}
									disabled={props.store.postingsPagination.isLoading}>
									<span class="tiny-button my-auto">
										<svg aria-hidden="true" viewBox="0 0 1 1">
											<use href="/typo3conf/ext/basicdistribution/Resources/Public/Icons/Symbols/sprite.symbol.svg#close" />
										</svg>
									</span>
									<span class="my-auto">Auswahl zurücksetzen</span>
								</button>
							</Show>
						</div>
					</div>
				</div>
			</Show>
		</div>
	)
}

export default FilterListMode
