import { Component, For, Match, Show, Switch, onCleanup, onMount, createSignal, createMemo } from 'solid-js'
import Multiselect, { DropdownData } from '../../../shared/components/Multiselect'
import { CareerStore } from '../career-list'
import { FILTER_OPTION_NAMES, FilterTypes } from '../shared/filter-configuration'
import Chips from '../../../shared/components/Chips'
import Breadcrumb, { BreadcrumbType } from '../../../shared/components/Breadcrumb'
import { CareerListQuery } from '@/shared/generated/graphql'
import FilterBubbles from './FilterBubbles'
import { useEventBus } from '../context/EventBusContext'
import Filters from './Filters'

type Props = {
	store: CareerStore
	onFilterChange: (path: string, items: DropdownData[]) => void
	onRangeFilterChange: (path: string, value: DropdownData[]) => void
	onFilterOptionDisabled: (path: string, value: string) => void
	onResetLocationSearch: () => void
	breadcrumb: BreadcrumbType[]
	resetFilter: () => void
}

type DiscreteFacet = Extract<CareerListQuery['postings']['facets'][number], { __typename: 'DiscreteFacet' }>

const Filter: Component<Props> = props => {
	const eventBus = useEventBus()

	const selectedFilterOptions = createMemo(() => props.store.visuallySelectedFilterOptions())

	const sourceFacet = () =>
		props?.store?.facets?.find((x): x is DiscreteFacet => x.path === 'source.name_short' && x.__typename === 'DiscreteFacet')

	const [showFilter, setFilter] = createSignal(false)
	const toggle = () => setFilter(!showFilter())

	const [mobile, isMobile] = createSignal(false)
	const handlerResize = (event: Event) => {
		if (window.matchMedia('(min-width: 992px)').matches) {
			isMobile(false)
		} else {
			isMobile(true)
		}
	}

	const onFilterChange = (path: string, items: DropdownData[]) => {
		props.onFilterChange(path, items)
	}

	onMount(() => {
		window.addEventListener('resize', handlerResize)
		window.addEventListener('load', handlerResize)
	})

	onCleanup(() => {
		window.removeEventListener('resize', handlerResize)
		window.removeEventListener('load', handlerResize)
	})

	return (
		<div class="career-filter">
			<Show when={mobile()}>
				<div class="filter-head" classList={{ 'border border-solid border-bordergrey': showFilter() }} onClick={toggle}>
					<div class="flex flex-row gap-4">
						<button class="filter-toggle">
							<div class="icon">
								<img
									src="/typo3conf/ext/basicdistribution/Resources/Public/Icons/Career/bwegt-icons-HR-filter.svg"
									alt="filter"
								/>
							</div>
							<Switch>
								<Match when={selectedFilterOptions().length == 0 && !showFilter()}>
									<span>Weitere Optionen</span>
								</Match>
								<Match when={selectedFilterOptions().length == 0 && showFilter()}>
									<span>Weniger Optionen</span>
								</Match>
							</Switch>
						</button>

						<div class="chip-wrapper flex flex-row gap-4">
							<Show when={selectedFilterOptions().length > 0}>
								<button
									type="button"
									class="flex items-center gap-2 rounded-md border border-yellow px-2 py-1 text-sm"
									disabled={props.store.postingsPagination.isLoading}
									onClick={() => props.resetFilter()}>
									<span class="filter-tag-text">Auswahl zurücksetzen</span>
									<span class="tiny-button my-auto">
										<svg aria-hidden="true" viewBox="0 0 1 1">
											<use href="/typo3conf/ext/basicdistribution/Resources/Public/Icons/Symbols/sprite.symbol.svg#close" />
										</svg>
									</span>
								</button>
							</Show>
							<FilterBubbles
								onResetLocationSearch={props.onResetLocationSearch}
								store={props.store}
								onFilterOptionDisabled={props.onFilterOptionDisabled}
							/>
						</div>
					</div>
				</div>
			</Show>

			<div class={showFilter() || !mobile() ? 'block' : 'hidden'}>
				<div class="filter-body">
					<Show when={!mobile()}>{<Breadcrumb breadcrumb={props.breadcrumb} />}</Show>
					<h2 class="filter-title">Filteroptionen</h2>
					<Filters
						store={props.store}
						onFilterChange={props.onFilterChange}
						onRangeFilterChange={props.onRangeFilterChange}
						onFilterOptionDisabled={props.onFilterOptionDisabled}
					/>

					<div class="apply-and-reset">
						<div class="flex flex-1 justify-center">
							<button
								type="submit"
								class="apply-filter-btn"
								onClick={() => {
									eventBus.emit({ type: 'searchTriggered' })
									toggle()
								}}>
								<svg aria-hidden="true" viewBox="0 0 512 512">
									<use href="/typo3conf/ext/basicdistribution/Resources/Public/Icons/Symbols/sprite.symbol.svg#ios-search-strong" />
								</svg>
								<span>Job finden</span>
							</button>
						</div>
						<div class="flex-1">
							<Show when={selectedFilterOptions().length > 0 && props.store.plz}>
								<button
									class="reset mt-6"
									type="button"
									onClick={() => props.resetFilter()}
									disabled={props.store.postingsPagination.isLoading}>
									<span class="tiny-button my-auto">
										<svg aria-hidden="true" viewBox="0 0 1 1">
											<use href="/typo3conf/ext/basicdistribution/Resources/Public/Icons/Symbols/sprite.symbol.svg#close" />
										</svg>
									</span>
									<span class="my-auto">Auswahl zurücksetzen</span>
								</button>
							</Show>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Filter
